import { useQuery, UseQueryOptions } from "react-query";
import { useRouter } from "next/router";
import { Session } from "next-auth";
import axios from "axios";

export const fetchSession = async (): Promise<Session | null> => {
  const res = await axios.get<Session>(`/api/auth/session`);
  const session = res.data;

  if (Object.keys(session).length) {
    return session;
  }

  return null;
};

interface SessionQueryOptions<R> {
  /** If set to `true`, the returned session is guaranteed to not be `null` */
  required?: R;
  /** If `required: true`, the user will be redirected to this URL, if they don't have a session */
  redirectTo?: string;
  /** Configuration for `useQuery` */
  queryConfig?: UseQueryOptions<Session | null>;
}

export default function useSession<R extends boolean = false>({
  required,
  redirectTo = "/signin",
  queryConfig = {}
}: SessionQueryOptions<R> = {}): [Session | null, boolean] {
  const router = useRouter();
  const query = useQuery(["auth-session"], fetchSession, {
    ...queryConfig,
    onSettled(data, error) {
      if (queryConfig.onSettled) queryConfig.onSettled(data, error);
      if (data || !required) return;
      router.push(redirectTo);
    }
  });

  return [query.data, query.status === "loading"];
}
