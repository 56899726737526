import localforage from "localforage";
import { useMutation } from "react-query";
import React, { createContext, useContext } from "react";

import useSession from "../hooks/use-auth-session";
import { sendAnalytics } from "../lib/api/analytics";
import { isBrowser } from "../lib/utils";

type AnalyticsContextType = {
  sendAnalytics: (event: AnalyticsEvent) => void;
};

type DeviceDetails = {
  browser: any;
  device: any;
};

const deviceDetails = (): DeviceDetails => {
  if (typeof window !== undefined) {
    const { ClientJS } = require("clientjs");
    const client = new ClientJS();

    const { browser, cpu, device, ua, os, engine } = client.getBrowserData();

    const _device = {
      osName: os.name,
      osVersion: os.version,
      cpuArch: cpu.architecture,
      deviceType: device.type,
      deviceModel: device.model,
      deviceVendor: device.vendor,
      fingerprint: client.getFingerprint()
    };

    const _browser = {
      userAgent: ua,
      browserName: browser.name,
      browserVersion: browser.version,
      browserEngine: engine.name,
      screenSize: client.getCurrentResolution()
    };

    return {
      device: _device,
      browser: _browser
    };
  }

  return {
    device: undefined,
    browser: undefined
  };
};

const extractParams = () => {
  if (isBrowser) {
    const cookies = document.cookie.split(";").reduce((res, c) => {
      const [key, val] = c.trim().split("=").map(decodeURIComponent);
      try {
        return Object.assign(res, { [key]: JSON.parse(val) });
      } catch (e) {
        return Object.assign(res, { [key]: val });
      }
    }, {});

    const callbackUrl =
      cookies["next-auth.callback-url"] || cookies["__Secure-next-auth.callback-url"];

    if (callbackUrl) {
      const { searchParams: params } = new URL(callbackUrl);

      const utmId = params.get("utm_id");
      const utmSource = params.get("utm_source");

      return {
        utmId,
        utmSource
      };
    }
  }

  return {};
};

export enum CategoryType {
  PAGELOAD = "PageLoad",
  AUTH = "Authentication",
  NAVIGATION = "Navigation",
  APP = "Application",
  PAGE = "Page"
}

export enum EventType {
  AUTO_SIGNIN = "AutoSignin",
  REDIRECT = "Redirect",
  URL_CLICK = "UrlClick",
  PAGELOAD = "PageLoad",
  PAGEEXIT = "PageExit",
  GET_OTP = "GetOTP",
  SIGNIN = "Signin",
  SEARCH = "Search",
  SHARE = "Share",
  ACCEPT_INSTALL = "AcceptInstall",
  CANCEL_INSTALL = "CancelInstall",
  APP_INSTALLED = "AppInstalled",
  IOS_SHOW_INSTALL = "IosShowInstall",
  ENABLE_NOTIFICATIONS = "EnableNotifications",
  DISABLE_NOTIFICATIONS = "DisableNotifications"
}

export const AnalyticsContext = createContext<AnalyticsContextType>(null);

export const useAnalytics = () => useContext(AnalyticsContext);

const AnalyticsProvider: React.FC = ({ children }) => {
  const [session] = useSession();
  // const { utmId, utmSource } = extractParams();

  const analyticsMutation = useMutation(sendAnalytics, {
    onSuccess: () => {
      // handle error if need be
    },
    onError: () => {
      // handle error
    }
  });

  const _sendAnalytics = async (event: AnalyticsEvent) => {
    const { device, browser } = deviceDetails();
    const { utmId, utmSource } = extractParams();
    const { userId, sessionId } = session?.user || {};

    const sessionInfo = {
      userId,
      sessionId,
      utmId,
      utmSource
    };

    const isStaging = window.location.host.includes("dev.angaza");

    const data = {
      ...sessionInfo,
      environment:
        process.env.NODE_ENV === "production"
          ? isStaging
            ? "staging"
            : "production"
          : "development",
      timestamp: Math.floor(new Date().getTime() / 1000),
      ...event,
      meta: {
        ...event.meta,
        device,
        browser
      }
    };

    const _events = await localforage.getItem<AnalyticsEvent[]>("analytics");
    const savedEvents = _events || [];
    const newEvents = [...savedEvents, data];

    localforage.setItem("analytics", newEvents);

    if (newEvents.length > 10 || (event.type == EventType.PAGELOAD && event?.page == "discover")) {
      analyticsMutation.mutate(
        newEvents.map(evt => ({
          ...evt,
          ...sessionInfo
        }))
      );

      localforage.setItem("analytics", []);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        sendAnalytics: _sendAnalytics
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
