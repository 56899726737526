import _axios from "axios";
import { BibleVerse } from "../types";

export const BASE_URL = "https://angaza-pwa.herokuapp.com/api/web/v2.0";

const createAxios = () => {
  const axios = _axios.create({
    baseURL: BASE_URL,
    headers: {
      "content-type": "application/json",
      "x-server-key": process.env.NEXT_PUBLIC_API_SERVER_KEY
    }
  });

  axios.interceptors.request.use(
    async config => {
      return config;
    },
    error => Promise.reject(error)
  );

  axios.interceptors.request.use(
    response => response,
    error => Promise.reject(error)
  );

  return axios;
};

const createAuthAxios = () => {
  const axios = _axios.create({
    baseURL: BASE_URL,
    headers: {
      "content-type": "application/json",
      "x-server-key": process.env.NEXT_PUBLIC_API_SERVER_KEY
    }
  });

  return axios;
};

export const api = createAxios();
export const authApi = createAuthAxios();

export const fetchSongs = async (page = 0, size = 10): Promise<ApiResponse<Songs>> => {
  const res = await api.get<ApiResponse<Songs>>(`/songs?type=Audio&pageSize=${size}&page=${page}`);
  return res.data;
};

export const fetchNewSongs = async (size = 10, page = 0): Promise<Songs> => {
  const res = await api.get<ApiResponse<Songs>>(`/songs/new?pageSize=${size}&page=${page}`);
  return res.data?.data;
};

export const fetchSongDetails = async (songId: string): Promise<Song> => {
  const res = await api.get<ApiResponse<Song>>(`/songs/${songId}`);
  return res.data?.data[0];
};

export const favouriteSong = async (reqData: {
  songId: string;
  status: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/songs/${reqData.songId}/favorite`, {
    status: reqData.status
  });

  return res.data?.data;
};

export const likeSong = async (reqData: { songId: string; like: boolean }): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/songs/${reqData.songId}/like`, {
    like: reqData.like
  });

  return res.data?.data;
};

export const fetchPlaylists = async (size = 10, page = 0): Promise<Playlists> => {
  const res = await api.get<ApiResponse<Playlists>>(`/playlist?pageSize=${size}&page=${page}`);
  return res.data?.data;
};

export const fetchPaginatedPlaylists = async (
  page = 0,
  size = 10
): Promise<ApiResponse<Playlists>> => {
  const res = await api.get<ApiResponse<Playlists>>(`/playlist?pageSize=${size}&page=${page}`);
  return res.data;
};

export const fetchPlaylist = async (playlistId: string): Promise<Playlist> => {
  const res = await api.get<ApiResponse<Playlist>>(`/playlist/${playlistId}`);
  return res.data?.data[0];
};

export const favouritePlaylist = async (reqData: {
  playlistId: string;
  status: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/playlists/${reqData.playlistId}/favorite`, {
    status: reqData.status
  });

  return res.data?.data;
};

export const likePlaylist = async (reqData: {
  playlistId: string;
  like: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/playlists/${reqData.playlistId}/like`, {
    like: reqData.like
  });

  return res.data?.data;
};

export const fetchArtists = async (size = 10, page = 0): Promise<Artists> => {
  const res = await api.get<ApiResponse<Artists>>(`/artists?pageSize=${size}&page=${page}`);
  return res.data?.data;
};

export const fetchArtist = async (artistId: string): Promise<Artist> => {
  const res = await api.get<ApiResponse<Artist>>(`/artists/${artistId}`);
  return res.data?.data[0];
};

export const fetchPaginatedArtists = async (page = 0, size = 10): Promise<ApiResponse<Artists>> => {
  const res = await api.get<ApiResponse<Artists>>(`/artists?pageSize=${size}&page=${page}`);
  return res.data;
};

export const fetchArtistSongs = async (
  artistId: string,
  size = 10,
  page = 0
): Promise<readonly Song[]> => {
  const res = await api.get<ApiResponse<readonly Song[]>>(
    `/artists/${artistId}/songs?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchPaginatedArtistSongs = async (
  artistId: string,
  page = 0,
  size = 10
): Promise<ApiResponse<Songs>> => {
  const res = await api.get<ApiResponse<Songs>>(
    `/artists/${artistId}/songs?pageSize=${size}&page=${page}`
  );
  return res.data;
};

export const followArtist = async (reqData: {
  artistId: string;
  status: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/artists/${reqData.artistId}/follow`, {
    status: reqData.status
  });

  return res.data?.data;
};

export const likeArtist = async (reqData: {
  artistId: string;
  like: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/artists/${reqData.artistId}/like`, {
    like: reqData.like
  });

  return res.data?.data;
};

export const fetchVideos = async (size = 10, page = 0): Promise<Videos> => {
  const res = await api.get<ApiResponse<Videos>>(`/videos?pageSize=${size}&page=${page}`);
  return res.data?.data;
};

export const fetchBibleVerse = async (): Promise<BibleVerse> => {
  const res = await api.get<ApiResponse<BibleVerse>>(`/bible/verseOfDay`);
  return res.data?.data;
};

export const fetchSermons = async (size = 10, page = 0): Promise<Sermons> => {
  const res = await api.get<ApiResponse<Sermons>>(`/sermons?pageSize=${size}&page=${page}`);
  return res.data?.data;
};

export const fetchChurches = async (size = 10, page = 0): Promise<Churches> => {
  const res = await api.get<ApiResponse<Churches>>(`/church?pageSize=${size}&page=${page}`);
  return res.data?.data;
};

export const fetchChurchDetails = async (churchId: string): Promise<Church> => {
  const res = await api.get<ApiResponse<Churches>>(`/church/${churchId}`);
  return res.data?.data[0];
};

export const fetchChurchSermons = async (
  churchId: string,
  page = 0,
  size = 10
): Promise<ApiResponse<Sermons>> => {
  const res = await api.get<ApiResponse<Sermons>>(
    `/church/${churchId}/sermons?type=Audio&pageSize=${size}&page=${page}`
  );
  return res.data;
};

export const fetchChurchVideoSermons = async (
  churchId: string,
  page = 0,
  size = 10
): Promise<ApiResponse<Videos>> => {
  const res = await api.get<ApiResponse<Videos>>(
    `/church/${churchId}/sermons?type=Video&pageSize=${size}&page=${page}`
  );
  return res.data;
};

export const followChurch = async (reqData: {
  churchId: string;
  status: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/church/${reqData.churchId}/follow`, {
    status: reqData.status
  });

  return res.data?.data;
};

export const likeChurch = async (reqData: {
  churchId: string;
  like: boolean;
}): Promise<boolean> => {
  const res = await api.put<ApiResponse<boolean>>(`/church/${reqData.churchId}/like`, {
    like: reqData.like
  });

  return res.data?.data;
};

export const fetchVideoSermons = async (size = 10, page = 0): Promise<Videos> => {
  const res = await api.get<ApiResponse<Videos>>(
    `/sermons?pageSize=${size}&page=${page}&type=Video`
  );
  return res.data?.data;
};

export const fetchPlaylistSongs = async (
  playlistId: string,
  page = 0,
  size = 10
): Promise<Songs> => {
  const res = await api.get<ApiResponse<Songs>>(
    `/playlist/${playlistId}/songs?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchPaginatedPlaylistSongs = async (
  playlistId: string,
  page = 0,
  size = 10
): Promise<ApiResponse<Songs>> => {
  const res = await api.get<ApiResponse<Songs>>(
    `/playlist/${playlistId}/songs?pageSize=${size}&page=${page}`
  );
  return res.data;
};

type OTPResponse = {
  status: string;
  success: boolean;
  message: string;
};

interface RequestOTPData {
  phone: string;
}

export const resendOTP = async (data: RequestOTPData): Promise<OTPResponse> => {
  const res = await authApi.post<OTPResponse>("/auth/resendOTP", data);
  localStorage.setItem("phoneNo", data.phone);
  return res.data;
};

interface Token {
  userId: string;
  token: string;
  expiresIn: number;
  onTrial: boolean;
  expirationDate: string;
  phoneHasSubscription: boolean;
  displayMessage: string;
}

export const verifyPhone = async (reqData: TokenRequest): Promise<TokenResponse> => {
  const res = await authApi.post<TokenResponse>("/auth/verifyPhone", reqData);
  const { data } = res.data;

  if (data.phoneValid) {
    const token = data?.token?.token;

    localStorage.setItem("token", token);
    api.defaults.headers.common["x-auth-token"] = `${token}`;
  }

  return res.data;
};

interface GenerateTokenRequest {
  phone: string;
  sendOTP: boolean;
}

interface GenerateTokenResponse {
  success: boolean;
  message: string;
  data: Token;
}

export const generateToken = async (
  reqData?: GenerateTokenRequest
): Promise<GenerateTokenResponse> => {
  try {
    let _reqData = reqData;

    if (!reqData) {
      _reqData = {
        phone: localStorage.getItem("phoneNo"),
        sendOTP: false
      };
    }

    const res = await authApi.post<GenerateTokenResponse>("/auth/genToken", _reqData);
    const { data, success } = res.data;

    if (success) {
      const now = new Date();
      const token = data?.token;
      now.setSeconds(now.getSeconds() + data?.expiresIn || 0);

      localStorage.setItem("token", token);
      localStorage.setItem("expiresAt", now.toISOString());
      api.defaults.headers.common["x-auth-token"] = `${token}`;
    }

    return res.data;
  } catch (error) {}
};

interface SetSkizaData {
  // phone: string;
  skizaCode: string;
}

export const setSkizaTune = (data: SetSkizaData): Promise<any> => {
  let phone: string;
  try {
    phone = localStorage.getItem("phoneNo");
  } catch {}
  return api.post("/safaricom/setSkiza", { skizaCode: data.skizaCode, phone });
};

export const fetchFavouriteSongs = async (userId: string, size = 10, page = 0): Promise<Songs> => {
  const res = await api.get<ApiResponse<Songs>>(
    `/users/${userId}/favouriteSongs?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchFavouriteArtists = async (
  userId: string,
  size = 10,
  page = 0
): Promise<Artists> => {
  const res = await api.get<ApiResponse<Artists>>(
    `/users/${userId}/favouriteArtists?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchFavouriteVideos = async (
  userId: string,
  size = 10,
  page = 0
): Promise<Videos> => {
  const res = await api.get<ApiResponse<Videos>>(
    `/users/${userId}/favouriteVideos?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchFavouritePlaylists = async (
  userId: string,
  size = 10,
  page = 0
): Promise<Playlists> => {
  const res = await api.get<ApiResponse<Playlists>>(
    `/users/${userId}/favouritePlaylists?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchFavouriteSermons = async (
  userId: string,
  size = 10,
  page = 0
): Promise<Sermons> => {
  const res = await api.get<ApiResponse<Sermons>>(
    `/users/${userId}/recentSermons?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};

export const fetchFavouriteChurches = async (
  userId: string,
  size = 10,
  page = 0
): Promise<Churches> => {
  const res = await api.get<ApiResponse<Churches>>(
    `/users/${userId}/churches?pageSize=${size}&page=${page}`
  );
  return res.data?.data;
};
