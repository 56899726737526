export const isBrowser = typeof window === "undefined" ? false : true;

// Detects if device is on iOS
export const isIos = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

// Detects if device is in standalone mode
export const isInStandaloneMode = (): boolean => {
  if (window.navigator && (window.navigator as any).standalone === true) {
    // is loaded from iOS home screen
    return true;
  } else if (window.matchMedia && window.matchMedia("(display-mode: standalone)").matches) {
    // is loaded from android home screen
    return true;
  } else {
    return false;
  }
};
